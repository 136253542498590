// const mobileBreakpoint = 820;
// let isMobile = window.innerWidth <= mobileBreakpoint;

window.addEventListener('load', function () {
  var bookingForm = document.getElementById('booking-form');
  if (bookingForm) {
    const withReturnCheckbox = document.getElementById('withReturn');
    const oneWayCheckbox = document.getElementById('oneWay');
    const fromPortDropdown = document.getElementById('from');
    const toPortDropdown = document.getElementById('to');
    const fromDatePicker = document.getElementById('fromDate');
    const toDatePicker = document.getElementById('toDate');

    const setRoutes = () => {
      const fromSelected = fromPortDropdown.selectedOptions[0];
      const route = {
        from: fromSelected.dataset.from,
        to: fromSelected.dataset.to
      };

      toPortDropdown.options.forEach(option => {
        const returnRoute = {
          from: option.dataset.from,
          to: option.dataset.to
        };
        option.disabled = (route.to !== returnRoute.from) || (route.from !== returnRoute.to);
      });
    };

    setRoutes();

    const dateConfig = {
      altInput: true,
      altFormat: "d.m.Y",
      dateFormat: "Y-m-d",
      minDate: "today"
    }

    const defaultDateFrom = new Date();
    const defaultDateTo = getDateIn(defaultDateFrom, 1);

    const fromFlatpickr = flatpickr(fromDatePicker, {
      ...dateConfig,
      defaultDate: defaultDateFrom,
      onChange: fromDateChange
    });
    const toFlatpickr = flatpickr(toDatePicker, {
      ...dateConfig,
      defaultDate: defaultDateTo
    });

    function fromDateChange(selectedDates) {
      if (!selectedDates.length) return;
      toFlatpickr.setDate(getDateIn(selectedDates[0], 1), null, "d.m.Y");
      toFlatpickr.set('minDate', selectedDates[0]);
      toFlatpickr.set('maxDate', getDateIn(selectedDates[0], 60));
    }

    fromPortDropdown.addEventListener('change', function (e) {
      const selected = e.target.selectedOptions[0];
      setRoutes();
      toPortDropdown.value = selected.dataset.return;
    })

    withReturnCheckbox.addEventListener('change', function (e) {
      oneWayCheckbox.checked = !this.checked;
      toPortDropdown.disabled = !this.checked;
      toFlatpickr.altInput.disabled = !this.checked;
      if (toFlatpickr.mobileInput) toFlatpickr.mobileInput.disabled = !this.checked;
    });

    oneWayCheckbox.addEventListener('change', function (e) {
      withReturnCheckbox.checked = !this.checked;
      toPortDropdown.disabled = this.checked;
      toFlatpickr.altInput.disabled = this.checked;
      if (toFlatpickr.mobileInput) toFlatpickr.mobileInput.disabled = this.checked;
    });

    bookingForm.addEventListener('submit', function (e) {
      if (fromPortDropdown.selectedOptions[0].dataset.url) {
        e.preventDefault();
        window.open(fromPortDropdown.selectedOptions[0].dataset.url, '_blank');
      } 
    })
  }
});

function getDateIn(date, count) {
  const resultDate = new Date(date);
  resultDate.setDate(resultDate.getDate() + count);
  return resultDate;
}